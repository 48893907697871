
import BTabItem from 'buetify/lib/components/tabs/BTabItem';
import BTabs from 'buetify/lib/components/tabs/BTabs';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'tabs-sizes-example',
	components: {
		BTabs,
		BTabItem
	}
});
