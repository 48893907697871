
import BTabItem from 'buetify/lib/components/tabs/BTabItem';
import BTabs from 'buetify/lib/components/tabs/BTabs';
import { defineComponent } from 'vue';
import UserCogIcon from '../../../../../components/icons/UserCogIcon';
import UserIcon from '../../../../../components/icons/UserIcon';
import UsersIcon from '../../../../../components/icons/UsersIcon';

export default defineComponent({
	name: 'tabs-icons-example',
	components: {
		BTabs,
		BTabItem
	},
	setup() {
		return {
			UserIcon,
			UserCogIcon,
			UsersIcon
		};
	}
});
