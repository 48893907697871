
import { BSelect, BSwitch } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import BTabItem from 'buetify/lib/components/tabs/BTabItem';
import BTabs, { TabsPosition, TabsSize, TabsType } from 'buetify/lib/components/tabs/BTabs';
import { defineComponent, shallowRef } from 'vue';

interface Option<T> {
	value: T;
	text: string;
}

const sizes: Option<TabsSize>[] = [
	{
		value: 'is-small',
		text: 'Small'
	},
	{
		value: '',
		text: 'Default'
	},
	{
		value: 'is-medium',
		text: 'Medium'
	},
	{
		value: 'is-large',
		text: 'Large'
	}
];

const positions: Option<TabsPosition>[] = [
	{
		value: '',
		text: 'Default (Left)'
	},
	{
		value: 'is-centered',
		text: 'Center'
	},
	{
		value: 'is-right',
		text: 'Right'
	}
];

const types: Option<TabsType>[] = [
	{
		value: '',
		text: 'Default'
	},
	{
		value: 'is-boxed',
		text: 'Boxed'
	},
	{
		value: 'is-toggle',
		text: 'Toggle'
	},
	{
		value: 'is-toggle-rounded',
		text: 'Toggle Rounded'
	}
];

export default defineComponent({
	name: 'simple-tabs-example',
	components: {
		BTabs,
		BTabItem,
		BSelect,
		BSwitch,
		BField
	},
	setup() {
		const showSocial = shallowRef(false);
		const isAnimated = shallowRef(false);
		const size = shallowRef<TabsSize>('');
		const position = shallowRef<TabsPosition>('');
		const activeTab = shallowRef(0);
		const type = shallowRef<TabsType>('');

		return {
			position,
			positions,
			showSocial,
			isAnimated,
			size,
			sizes,
			activeTab,
			type,
			types
		};
	}
});
