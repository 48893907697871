
import { BField, BSelect } from 'buetify/lib/components';
import BTabItem from 'buetify/lib/components/tabs/BTabItem';
import BTabs, { TabsPosition, TabsSize } from 'buetify/lib/components/tabs/BTabs';
import { defineComponent, shallowRef } from 'vue';
import UserCogIcon from '../../../../../components/icons/UserCogIcon';
import UserIcon from '../../../../../components/icons/UserIcon';
import UsersIcon from '../../../../../components/icons/UsersIcon';

interface Option<T> {
	value: T;
	text: string;
}

const sizes: Option<TabsSize>[] = [
	{
		value: 'is-small',
		text: 'Small'
	},
	{
		value: '',
		text: 'Default'
	},
	{
		value: 'is-medium',
		text: 'Medium'
	},
	{
		value: 'is-large',
		text: 'Large'
	}
];

const positions: Option<TabsPosition>[] = [
	{
		value: '',
		text: 'Default'
	},
	{
		value: 'is-right',
		text: 'Right'
	}
];

export default defineComponent({
	name: 'tabs-vertical-example',
	components: {
		BTabs,
		BTabItem,
		BField,
		BSelect
	},
	setup() {
		const size = shallowRef<TabsSize>('');
		const position = shallowRef<TabsPosition>('');

		return {
			position,
			positions,
			size,
			sizes,
			UserIcon,
			UserCogIcon,
			UsersIcon
		};
	}
});
